export const calculateFinalPrice = ({
    priceAgency = null,
    surchargeAgency = null,
    provisionPZH = null,
    surchargePZH = null,
}) =>
    [priceAgency, surchargeAgency, provisionPZH, surchargePZH].reduce(
        (carry, price) => (price ? carry + Number.parseFloat(price) : carry),
        0
    );
