import { useCallback, useRef, useState } from 'react';

export const useCallbackRef = () => {
    const [, setCurrent] = useState(null);
    const ref = useCallback(
        (node) => {
            setCurrent(node);
            ref.current = node;
        },
        [setCurrent]
    );

    return ref;
};

// Maybe better name is useStableFunc?
export const useCallbackFunc = (func) => {
    const latestRef = useRef({});

    // On every render save newest function to latestRef
    latestRef.current.func = func;

    // On the first render create new function which will never change
    // but call newest function
    if (!latestRef.current.stable) {
        latestRef.current.stable = (...args) => latestRef.current.func(...args);
    }

    return latestRef.current.stable;
};
